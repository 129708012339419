.lexikon {
    h1 {
        font-weight: bold;
        font-size: 28px;

        & + p {
            color: $schriftfarbe;
            margin: 0 0 40px 0;
            line-height: 120%;
            font-size: 16px;
        }
    }

    .gloss_list_alph {
        height: auto;
        text-align: center;
        background: $blau;
        border-radius: 5px;
        box-shadow: $schattenU;
        font-size: 100px;
        line-height: 183px;
        color: #fff;

        &:target {
            background: $tuerkis;

            ~ a {
                border: 2px solid $tuerkis;
            }

            ~ a + div ~ a {
                border: none;
            }
        }
    }

    .gloss_alph {
        background: #fff;
        border-radius: 5px;
        box-shadow: $schattenU;
        font-size: 20px;
        text-align: center;
        transition: all 300ms ease-in-out;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        overflow: hidden;

        div {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 20px;
        }

        &:hover {
            transition: all 300ms ease-in-out;
            background: $tuerkis;
            transform: scale(1.1);
            color: #fff;
            border: none !important;

            div {
                color: #fff;
            }
        }
    }

    .gloss_list_article {
        display: grid;
        gap: 1vw;
        grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
        grid-auto-rows: 1fr;
    }
}
