div.form {
    font-family: arial;
    font-size: 12pt;
    color: #666666;
    max-width: 740px;
    line-height: 1.4em;
    margin: 0 auto;
}

div.form .form_border {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
    -sand-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
    min-width: 740px;
    margin: 0 0 40px 0;
    position: relative;
    box-sizing: border-box;
    padding: 30px;
}

.form_border ul li label {
    width: 100px;
    display: inline-block;
}

.newsletter-reg div.form ul li {
    height: 50px;
}

div.form .form_field {
    border-bottom: 2px solid RGB(217, 217, 217);
    width: 80%;
    padding: 0.2em 0;
}

div.form .form_field_error {
    border: 1px solid #e74c3c;
}

div.form .form_button_submit {
    position: relative;
    overflow: hidden;
    background: RGB(47, 128, 203);
    border-radius: 5px;
    color: white;
    height: 38px;
    line-height: 38px;
    padding: 0 32px;
    font-size: 14px;
    font-weight: 400;
    transition: background 0.3s;
    -sand-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -webkit-transition: background 0.3s;
    cursor: pointer;
}

div.form .rsError {
    background-color: #e74c3c;
}

.newsletter-reg h2 {
    font-weight: bold;
    font-size: 20px;
    color: RGB(158, 158, 158);
    margin-bottom: 32px;
}

li#gender_form input {
    margin: 0 10px 0 0;
}

li#gender_form {
    height: auto;
    display: flex;
}
