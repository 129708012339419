.startseite .se {
    overflow: visible !important;
}

.startseite .kv-banner:not(.flex) .kv-button {
    position: absolute;
    bottom: 25px;
    right: 20px;
    min-width: 140px;
    padding: 0 30px;
    margin: 0 auto;
    text-align: center;
    min-height: 50px !important;
}

.kv-banner.one .kv-button {
    right: 0;
}

.kv-banner.small .kv-button {
    min-width: 170px;
}

.startseite ul.list_se {
    margin: 30px 0 0 0;
    padding: 0;
}

.kv-banner.one.gesichtsform img {
    height: auto;
    max-height: 210px;
    width: fit-content;
    margin: 0 auto;
}

.kv-banner.one.gesichtsform h2 {
    color: $blau;
    text-align: center;
    margin: 30px 0 0 0;
}

.kv-banner.no-img .bottom {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 35px;
}

.kv-banner.blue {
    background: $blau;
}

.kv-banner.blue .ueberschrift,
.kv-banner.blue .beschreibung {
    color: #fff !important;
}

.kv-banner.no-img .ueberschrift {
    margin: 0 0 30px 0;
}

.kv-banner.blue .kv-button {
    background: #fff;
    color: $blau;
}

.zeile_1 {
    height: 365px;
    overflow: hidden;
    margin: 0 0 50px 0;
}

.startseite .list_se {
    margin: 30px 0 150px 0;
}

.startseite .top_marken {
    grid-row-end: span 4;
    max-height: 295px;
    overflow: hidden;
    padding: 0 0 5px 0;
}

section.section.top_marken .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
    grid-gap: 16px;
    grid-auto-flow: column;
}

.section.top_marken .alle_her {
    grid-row-end: span 2;
}

li.break,
.top-marken {
    grid-column-end: span 7;
    background: transparent;
}

section.section.huge,
section.section.small {
    a {
        &:hover .kv-link {
            gap: 25px;
            transition: gap 0.1s;
        }
    }

    .huge {
        grid-row-end: span 6;
        grid-column-end: span 5;
        flex-direction: row;

        h1,
        h2 {
            font-size: 36px;
            margin: 0 0 20px 0;
            color: #4b5265;
        }
    }

    .small {
        grid-row-end: span 4;
        grid-column-end: span 3;
        // flex-direction: row;
        a {
            flex-direction: row;
        }

        h1,
        h2 {
            font-size: 24px;
            margin: 0 0 20px 0;
            color: #4b5265;
        }

        .text .kv-link {
            font-size: 16px;
        }
    }

    // img {
    //     width: auto;
    //     height: 100%;
    // }

    .text {
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;

        .kv-link {
            font-size: 20px;
            color: $blau;
            display: flex;
            align-items: center;
            gap: 10px;
            transition: gap 0.1s;

            img {
                width: auto;
            }
        }
    }
}

@media (max-width: 1653px) {
    section.section.huge {
        .huge {
            grid-row-end: span 6;
            grid-column-end: span 5;
        }
    }
}

@media (max-width: 1404px) {
    section.section.huge {
        .huge {
            grid-row-end: span 5;
            grid-column-end: span 4;
        }
    }
}

@media (max-width: 1179px) {
    section.section.huge {
        .huge {
            grid-row-end: span 8;
            grid-column-end: span 3;
            flex-direction: column;
        }
    }
}

@media (max-width: 760px) {
    section.section.huge {
        .huge {
            grid-column-end: span 2;
            flex-direction: column;
        }
    }

    section.section.small {
        .small {
            grid-column-end: span 2;
            a {
                flex-direction: column;
            }
        }
    }
}

.kv-banner section.section li.small {
    grid-row-end: span 4;
    grid-column-end: span 2;
}

.kv-banner.flex {
    grid-row-end: span 4;
}

.kv-banner.flex .ueberschrift {
    font-size: 25px;
}

.kv-banner.flex .kv-button {
    height: initial;
    margin: 20px 0 0 0;
}

.kv-banner.flex .bottom {
    justify-content: space-between;
    display: flex;
}

section.section {
    margin: 0 0 60px 0;
    padding: 0 0 5px 0;
}

section.section ul.list_se:first-child {
    padding: 50px 0 0 0;
}

section.section.benefits {
    max-height: none;
}

section.top_marken .alle_her {
    width: 236px;
    margin: 0;
}

.huge ul li:nth-child(n + 18) {
    display: none !important;
}

.small ul li:nth-child(n + 13) {
    display: none !important;
}

.small .kv-banner.small {
    grid-row-end: span 4 !important;
    grid-column-end: span 2;
}

.kv-banner.small a {
    justify-content: flex-start;
}

.h-2 {
    max-height: 770px;
    overflow: hidden;
}

.h-3 {
    max-height: 1100px;
    overflow: hidden;
}

section.section h1,
section.section h2 {
    font-size: 26px;
    margin: 0 8px 20px 0;
    width: 100%;
    color: $agrau;
}

ul.hutfinder {
    grid-template-columns: minmax(236px, auto) repeat(auto-fit, 236px);
}

.startseite .top_marken .alle_her {
    background: $blau;
    float: none;
    width: 236px;
    margin: 0;
}

.fs-timeline {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(236px, auto));
    grid-auto-rows: minmax(0, 65px);
    grid-gap: 16px;
    grid-auto-flow: dense;
    max-width: 1748px;
    overflow-y: hidden;
    max-height: 970px;
}

div.fs-timeline > div {
    width: 100% !important;
    height: 100%;
    padding: 0 !important;
    grid-row-end: span 4;
    grid-column-end: span 1;
    display: grid;
}

.fs-next-page {
    display: none;
}

.fs-has-links {
    display: none;
}

.fs-wrapper div.fs-text-container .fs-entry-title,
div.fs-detail-title {
    font-family: roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

div.fs-text-container .fs-entry-date,
div.fs-detail-container .fs-post-info,
div.fs-wrapper div.fs-has-links::after,
.fs-text-product,
.fs-overlink-text {
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.fs-wrapper div.fs-text-container * {
    color: #ffffff;
}

.fs-wrapper div.fs-text-container {
    background-color: rgba(42, 175, 186, 0.8);
    margin: 0px;
}

div.fs-entry-date {
    display: none;
}

div.fs-entry-title {
    display: none;
}

.fs-sizes_v2_5.fs-wrapper div.fs-timeline-entry {
    margin: 0;
    border-radius: 5px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.fs-wrapper .fs-service-icon i {
    font-style: normal;
    color: #fff;
    font-size: 46px;
}

.fs-desktop .fs-detail-container {
    background-color: white;
    border-radius: 5px;
}

div.fs-detail-title {
    text-align: left;
}

.fs-text-product-cta:after {
    border-width: 0px;
    border-style: solid;
    max-width: 120px;
    background: #2f80cb;
    color: #fff;
    border-radius: 5px;
}

.fs-text-product-cta:hover:after {
    background-color: rgba(42, 175, 186) !important;
}

svg.fs-next-svg {
    border: 2px solid $blau;
    padding: 15px;
    border-radius: 50px;

    path {
        stroke: $blau;
    }

    &:hover path {
        stroke: $blau !important;
    }
}

.gesichtsform.kv-banner a {
    display: initial;
}

.neu .kv-banner .kv-button {
    background: #fff;
    color: $blau;
}

@media (max-width: 1899px) {
    li.break,
    .top-marken {
        grid-column-end: span 6;
    }

    .huge ul li:nth-child(n + 15) {
        display: none !important;
    }

    .small ul li:nth-child(n + 11) {
        display: none !important;
    }

    .startseite .benefits ul li:nth-child(n + 7) {
        display: none !important;
    }
}

@media (max-width: 1653px) {
    li.break,
    .top-marken {
        grid-column-end: span 5;
    }

    .huge ul li:nth-child(n + 12) {
        display: none !important;
    }

    .small ul li:nth-child(n + 9) {
        display: none !important;
    }

    .startseite .benefits ul li:nth-child(n + 6) {
        display: none !important;
    }
}

@media (max-width: 1404px) {
    li.break,
    .top-marken {
        grid-column-end: span 4;
    }

    .huge ul li:nth-child(n + 10) {
        display: none !important;
    }

    .kv-banner.flex {
        grid-column: 1 / -1;
        grid-row-end: span 3;
    }

    .small ul li:nth-child(n + 7) {
        display: none !important;
    }

    .startseite .benefits ul li:nth-child(n + 5) {
        display: none !important;
    }
}

@media (max-width: 1179px) {
    li.break,
    .top-marken {
        grid-column-end: span 3;
    }

    .kv-banner.flex {
        grid-column: 1 / 3;
        grid-row-end: span 4;
    }

    .kv-banner.huge {
        grid-row-end: span 7;
        grid-column-end: span 3;
    }

    .huge ul li:nth-child(11) {
        display: block !important;
    }

    .huge ul li:nth-child(n + 12) {
        display: none !important;
    }

    section.section ul.list_se:first-child {
        padding: 0;
    }

    .startseite .section.benefits ul li {
        display: block !important;
    }

    .startseite .benefits ul li:nth-child(n + 7) {
        display: none !important;
    }

    .small ul li:nth-child(n + 5) {
        display: none !important;
    }
}

@media (max-width: 760px) {
    .kv-banner.flex .ueberschrift {
        font-size: 28px;
    }

    .kv-banner.blue .beschreibung {
        font-size: 24px;
    }

    #FooterJumperBanner .text {
        width: fit-content !important;
    }

    .fs-timeline {
        grid-template-columns: repeat(auto-fill, minmax(254px, 1fr));
        grid-auto-rows: minmax(0, 77px);
        max-height: 740px;
    }

    .h-4.no-banner {
        max-height: 2550px;
    }

    .startseite .kv-banner.small {
        grid-row-end: span 4;
    }

    .se.hutfinder li {
        grid-row-end: span 3;
    }

    .se.hutfinder li:first-child {
        grid-row-end: span 2;
    }

    .kv-banner.one.gesichtsform img {
        max-height: none;
    }

    ul.hutfinder {
        grid-template-columns: repeat(2, 354px);
    }

    section.section h1 {
        font-size: 36px;
    }

    li.break,
    .top-marken {
        grid-column-end: span 2;
    }

    .huge ul li:nth-child(12) {
        display: block !important;
    }

    .huge ul li:nth-child(n + 13) {
        display: none !important;
    }

    .kv-banner.huge {
        grid-row-end: span 4;
        grid-column-end: span 2;
    }

    .h-4 {
        max-height: 2590px;
    }

    .h-2 {
        max-height: 1560px;
    }

    .h-3 {
        max-height: 2100px;
    }

    ul.hutfinder {
        grid-template-columns: repeat(2, 354px);
        grid-auto-rows: minmax(0, auto);
    }

    section.section.top_marken .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 354px);
        grid-auto-rows: minmax(0, auto);
        gap: 16px;
        grid-auto-flow: dense;
    }

    .top_marken {
        max-height: 855px;
    }

    .top_marken .alle_her {
        width: 100% !important;
    }
}
