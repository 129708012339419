.umfrage-wrapper-newsletterabmeldung {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: $schattenU;
    border-radius: 8px;
    padding: 30px 50px;
    background: #ffffff;

    &.nl-abmeldung {
        .sonstiges,
        .senden {
            display: none;
        }
    }

    #newsletter_abmelden {
        > div {
            color: #a5a5a5;
        }

        .form_border {
            min-width: auto;
            border: none;
            box-shadow: none;
        }
    }

    img.bestaetigung {
        display: block;
        margin: 0 auto 30px auto;
    }

    .einleitung {
        padding: 20px 20px 0 20px;
    }

    .teaser {
        font-weight: 700;
        font-size: 18px;
        color: #a5a5a5;
        text-align: center;
        margin-bottom: 30px;
    }

    .frage {
        color: #a5a5a5;
        font-size: 13px;
    }

    .email-eintragen {
        order: 2;
        font-size: 13px;
        padding: 0 20px 20px 20px;

        .form {
            display: flex;
            justify-content: center;
            padding-top: 20px;

            label {
                font-weight: 700;
                padding-right: 5px;
            }

            .email {
                border-bottom: 2px solid #a5a5a5;
                width: 200px;
            }

            .submit {
                display: block;
                background: $blau;
                color: #ffffff;
                border-radius: 5px;
                font-size: 14px;
                padding: 8px;
                cursor: pointer;
                width: 270px;
                margin: 20px auto;
            }
        }
    }
}

.umfrage-bestellabschluss + .umfrage-newsletterabmeldung {
    display: none !important;
}

.umfrage-bestellabschluss,
.umfrage-newsletterabmeldung {
    background: #ffffff;
    padding: 20px;
    flex: 0 1 calc(236px);
    border: 3px solid $blau;

    &.umfrage-fertig {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2f80cb;
        font-weight: 700;
    }

    .teaser {
        font-weight: 300;
    }

    .frage {
        margin: 10px 0;
        font-size: 16px;
        color: $blau;
    }

    .auswahl {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .item {
            position: relative;
            flex: 1 100%;
            margin: 7px;
            background: #adb4be;
            border-radius: 5px;
            padding: 8px;
            text-align: center;
            box-sizing: border-box;

            > input {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                cursor: pointer;

                &.oberthema {
                    + label {
                        padding-left: 0;
                    }

                    &.active {
                        &:not(#gesundheit) {
                            + label {
                                margin-bottom: 8px !important;
                                display: block;
                                text-align: center;
                            }
                        }

                        ~ .sonstiges {
                            display: flex;
                            height: auto;
                            margin: 5px 0 0 0;
                            position: inherit;
                            font-size: 12px;
                        }

                        &:after {
                            content: " ";
                            background: url(#{$cdnPfad}/check_w.svg) no-repeat 0 (0 / contain);
                            position: absolute;
                            display: block;
                            width: 20px;
                            height: 20px;
                            right: 10px;
                            top: 9px;
                        }
                    }
                }

                &.active {
                    ~ .subitem {
                        display: flex;
                        margin: 0;
                        padding: 5px;

                        > input[type="checkbox"] {
                            display: none;

                            + label {
                                display: flex;
                                align-items: center;
                                cursor: pointer;

                                &:before {
                                    content: "";
                                    display: block;
                                    float: left;
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 12px;
                                    border: 2px solid $blau;
                                    background-color: #fff;
                                    margin-right: 8px;
                                }
                            }

                            &:checked + label {
                                &:before {
                                    box-shadow: inset 0px 0px 0px 3px #fff;
                                    background-color: $blau;
                                }
                            }
                        }
                    }
                }
            }

            > label {
                font-size: 14px;
                color: #ffffff;
                width: 100%;
                text-align: left;
            }

            .subitem,
            .sonstiges {
                display: none;
            }
        }
    }

    .sonstiges {
        width: 100%;
        padding: 8px;
        text-align: left;
        border-radius: 5px;
        margin: 7px;
        color: #a0a0a0;
        border: 2px solid #cccccc;
        box-sizing: border-box;
        font-size: 14px;
        cursor: auto !important;

        &::-webkit-input-placeholder {
            color: #d1d1d1;
        }
    }

    .senden {
        background: $blau;
        color: #ffffff;
        border-radius: 5px;
        font-size: 14px;
        padding: 8px;
        cursor: pointer;
        margin: 7px;
        width: 100%;
    }
}

.umfrage-newsletterabmeldung {
    border: none;
    padding: 20px 20px 0 20px;
    margin-left: 0;

    .auswahl {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .item {
            background: #a5a5a5;

            > input.oberthema.active:not(#gesundheit) + label {
                margin-bottom: 0 !important;
            }
        }

        .item,
        .sonstiges,
        .senden {
            width: 270px;
        }

        .sonstiges {
            min-height: 120px;
        }
    }
}

.umfrage-bestellabschluss.umfrage-fertig {
    align-items: flex-start;
    flex-direction: column;

    p {
        margin-top: 15px;
    }

    .zur-umfrage {
        background: $blau;
        padding: 10px 20px;
        border-radius: 5px;
        color: #ffffff;
        margin-top: 15px;
        display: inline-block;
    }
}

@media (max-width: 1180px) {
    .umfrage-newsletterabmeldung {
        margin: 16px 0;
    }
}

@media (max-width: 760px) {
    .umfrage-bestellabschluss,
    .umfrage-newsletterabmeldung {
        padding: 0 20px;

        &.umfrage-fertig {
            font-size: 26px;
        }

        .teaser {
            font-size: 24px;
        }

        .frage {
            font-size: 34px;
        }

        .auswahl {
            .item {
                padding: 14px;

                > input {
                    &.active {
                        ~ {
                            .subitem {
                                > label:before {
                                    top: 12px;
                                }

                                > input[type="checkbox"] + label:before {
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 18px;
                                }
                            }
                        }

                        ~ .subitem > input {
                            width: 170px;
                        }

                        &.oberthema {
                            &:after {
                                top: 22px;
                                right: 22px;
                            }

                            &.active ~ .sonstiges {
                                font-size: 20px;
                            }
                        }

                        &:after {
                            top: 13px;
                        }
                    }
                }

                > label {
                    font-size: 26px;
                }

                &.infos {
                    span {
                        text-align: right;
                    }
                }

                .row {
                    padding: 2px 0;
                }
            }
        }

        .sonstiges {
            padding: 14px;
            font-size: 26px;
        }

        .senden {
            padding: 14px;
            font-size: 26px;
        }
    }

    .umfrage-newsletterabmeldung {
        .teaser,
        .frage {
            font-size: 30px;
        }

        .auswahl {
            .item,
            .sonstiges,
            .senden {
                width: 80%;
            }
        }
    }

    .umfrage-wrapper-newsletterabmeldung {
        padding: 20px;

        #newsletter_abmelden > div {
            font-size: 26px;
        }

        .teaser,
        .frage {
            font-size: 26px;
        }

        .email-eintragen {
            > div {
                font-size: 30px;
            }

            .form {
                label,
                .submit,
                .email {
                    font-size: 30px;
                }

                .submit {
                    width: 100%;
                    padding: 14px;
                    font-size: 26px;
                }
            }
        }
    }
}
