@mixin browserPrefixes($property, $value, $vendors: webkit moz ms, $default: true) {
    @if $vendors {
        @each $vendor in $vendors {
            #{"-" + $vendor + "-" + $property}: #{$value};
        }
    }

    @if $default {
        #{$property}: #{$value};
    }
}

@mixin clearfix {
    &:after {
        content: " ";
        display: block;
        clear: both;
    }
}
