.top_marken {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .alle_her {
        background: $blau;
        float: none;

        > a {
            color: #fff;
        }
    }

    h1 {
        font-size: 26px;
        margin: 40px 8px 20px 8px;
        width: 100%;
    }

    + h2 {
        font-size: 20px;
        margin: 20px 8px 20px 8px;
    }
}

.alle_her {
    display: flex;
    justify-content: center;
    width: 226px;
    height: 176px;
    margin: 8px;
    float: left;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: $schattenU;
    position: relative;

    img {
        padding: 28px 15px;
        width: 170px;
        max-height: 90px;
    }

    > a {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 15px;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: #ccc;
        font-weight: 300;
    }

    .her_alle_pichold {
        align-self: center;
    }

    &.nologo > a {
        bottom: 60px;
        color: #999;
        font-weight: 400;
        font-size: 29px;
    }
}

.top_marken {
    white-space: nowrap;
    text-align: left;
    display: flex;

    ul {
        height: auto;
        width: 100%;
        max-width: 1530px;
        margin: 0 auto;
        display: block;
        font-size: 0;
        max-height: 170px;
        overflow: visible;
        white-space: nowrap;

        &.se.list_se li {
            background: #fff;
            width: 236px;
            height: 155px;
            min-height: 0;
            display: block;
            margin: 8px;
            border-radius: 5px;
            box-shadow: $schattenU;
            position: relative;
            display: inline-block;
        }

        li img {
            max-height: 100%;
            max-width: 70%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.html_herst_liste {
    margin-bottom: 30px;

    .html_herst_liste_box {
        width: 44px;
        height: 44px;
        background: #fff;
        border-radius: 5px;
        display: inline-block;
        margin: 8px;
        text-align: center;
        padding-top: 12px;
        box-sizing: border-box;
        font-size: 20px;
        color: $blau;
    }
}
