span.tab_se_unten {
    display: block !important;
    color: transparent; //schnellfix weil komische zeichen drin sind

    #bread {
        display: none;
    }
}

#pag_se {
    &.seite_1 {
        #pag_zur {
            a {
                cursor: default;
                opacity: 0.3;
            }
        }
        #pag_1 {
            display: none;
        }
    }

    ul {
        display: flex;
        justify-content: center;

        li {
            &:not(.nopag):hover svg path {
                fill: #2f80cb;
            }

            #pag_seiten {
                font-size: 1.5rem;
            }

            > * {
                margin: 0 0.5rem;
                min-width: 40px;
                height: 4rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        #pag_zur svg {
            transform: rotate(90deg);
        }

        #pag_vor svg {
            transform: rotate(-90deg);
        }
    }
}

.se,
#shopMitte2 .se:not(.alle_artikel_geladen):not(.keineLadeAnimation) {
    background: none !important;
}

@media (max-width: 768px) {
    #pag_se ul {
        margin: 0 0 30px 0;

        li #pag_seiten {
            font-size: 2.4rem;
        }

        > * {
            min-width: 100px;
            height: 7rem;
        }

        svg {
            width: 60px;
            height: auto;
        }
    }
}
