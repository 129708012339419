/* SEO2.0 */

.kv-no-br-wrapper {
    // das ist damit um Wörter, wie l´année ein wrapper gezogen wird,
    // damit sie nicht getrennt werden, wenn der Platz nicht reicht
    font-size: inherit;
    color: inherit;
    white-space: nowrap;
    font-weight: inherit;
    text-decoration: inherit;
    font-family: inherit;
}

#FooterJumperBanner.startseite {
    .info-inh {
        display: none;
    }

    .info-text {
        max-width: 100%;
    }
}

.fb.text2 {
    max-width: 1244px;
    margin: 0 auto !important;
    padding: 50px 126px !important;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    box-shadow: $schattenU;
    flex-shrink: 0;
    flex-grow: 1;

    .block.collapse + .block.collapse {
        margin: -36px 0 0 0;
    }

    i {
        font-size: inherit;
    }

    h1,
    h2,
    h3,
    h4 {
        font-weight: 700;
        line-height: 135%;
        margin-bottom: 20px;
        column-span: all;
        color: $blau;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2.1rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.3rem !important;
    }

    img {
        width: 100%;
        border-radius: 5px;
    }

    figure {
        position: relative;

        figcaption {
            position: absolute;
            padding: 2px 0 5px 20px;
            right: 0;

            a {
                color: #ccc;
                font-size: 0.9rem !important;
            }
        }
    }

    .block {
        margin: 20px 0 35px 0;
    }

    ul,
    ol {
        &.numersich {
            li {
                list-style-type: decimal !important;

                &:before {
                    content: none !important;
                }
            }
        }

        margin: 0;

        li {
            list-style-type: none !important;
        }
    }

    ul {
        li {
            position: relative;
            list-style-type: none;

            &:before {
                content: "●";
                color: $blau;
                position: absolute;
                left: -15px;
                top: 0;
            }
        }
    }

    .info-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        b {
            font-weight: 700;
            font-size: inherit;
        }

        .info-inh {
            max-width: 236px;
            margin: 0 0 0 16px;
            box-sizing: border-box;
            width: 100%;
            gap: 16px;

            div {
                background: #e5e5e5;
                padding: 16px;
                border-radius: 5px;
                margin: 0 0 16px 0;
            }

            h3 {
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 135%;
                margin-bottom: 20px;
            }

            ol {
                padding: 0 0 0 15px;
                margin: 0 !important;

                li {
                    list-style: decimal !important;
                    font-size: 1.2rem;
                    font-weight: 400;
                    padding: 0 0 30px 0;

                    a {
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 120%;
                        color: $blau;
                    }
                }

                ::marker {
                    color: $blau;
                }
            }
        }

        .info-text {
            max-width: 744px;
            width: 100%;

            p,
            table {
                margin-bottom: 30px;
            }

            p,
            li,
            th,
            td {
                line-height: 130%;
                font-weight: 400;
                font-size: 1.2rem;

                b,
                strong {
                    font-weight: 700;
                    font-size: inherit;
                }
            }

            a {
                font-size: 1.2rem;
                font-weight: 700;
                color: $blau;

                &.button {
                    color: #fff;
                }
            }

            figure {
                width: 100%;
                margin: 0 0 30px 0;

                + p,
                + h4 {
                    margin-top: 20px;
                }

                &.img-small {
                    min-width: 250px;
                    flex: 1 1;
                    margin: 0;
                }
            }

            ul {
                li {
                    h4 {
                        flex: 1 1 100%;
                    }

                    ul,
                    p {
                        flex: 1;
                        min-width: 200px;
                    }
                }
            }

            table {
                width: 100%;
                border-spacing: 0;
                border-collapse: 0;

                th {
                    background-color: #f2f2f2;
                    border-bottom: 1px solid $schriftfarbe;
                    font-weight: 700;
                }

                th,
                td {
                    padding: 20px 20px;
                }

                tbody {
                    tr:nth-child(even) {
                        background: #f2f2f2;
                    }
                }

                .right {
                    text-align: right;
                }

                .center {
                    text-align: center;
                }

                .left {
                    text-align: left;
                }
            }

            .info-box {
                background: $background;
                border-radius: 5px;
                padding: 16px;

                h3 {
                    margin: 10px 0 10px 0;
                }

                + figure {
                    margin-bottom: 0;
                }
            }

            .flex {
                display: flex;
                flex-wrap: wrap;

                h2,
                h3,
                h4 {
                    flex: 1 1 100%;
                }

                * {
                    flex: 1;
                }

                p + figure {
                    margin: 0 0 20px 20px;
                }

                figure + p {
                    margin: 0 20px 20px 0;
                }
            }

            .col-2 {
                display: flex;
                margin: 0 0 30px 0;
                gap: 16px;

                &.img-links {
                    > figure {
                        order: -1;
                    }
                }

                .info-box {
                    min-width: 126px;
                    box-sizing: border-box;
                    max-width: 40%;
                }

                ul {
                    li {
                        font-size: 1.2rem;
                        font-weight: 400;
                        padding: 0 0 15px 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 1404px) {
    .fb.text2 .info-wrapper .info-text {
        .col-2 {
            flex-direction: column;

            .info-box {
                margin: 0 0 16px 0;
                max-width: none;
            }

            figure.img-small {
                margin: 0;
            }

            &.img-links,
            &.img-rechts {
                > figure,
                > .info-box {
                    margin: 10px 0;
                }

                > .info-box {
                    order: 1;
                }
            }
        }
    }
}

@media (max-width: 1179px) {
    .fb.text2 {
        padding: 50px 30px !important;
    }
}

@media (max-width: 840px) {
    .fb.text2 {
        padding: 50px 15px !important;

        h1 {
            font-size: 3.2rem;
        }

        h2 {
            font-size: 2.8rem;
        }

        h3 {
            font-size: 2.1rem !important;
        }

        figure figcaption a {
            font-size: 1.6rem !important;
        }

        .info-wrapper {
            flex-direction: column;

            .info-text {
                h4 {
                    font-size: 2rem !important;
                }

                p,
                a {
                    font-size: 1.6rem;
                }

                li,
                li a {
                    font-size: 26px !important;
                }

                .col-2 {
                    display: flex;
                    margin: 0 0 60px 0;
                }
            }

            .info-inh {
                max-width: none;
                margin: 0 0 60px 0;
                display: flex;

                > div {
                    margin: 0 0 5px 0;
                }

                ol {
                    padding: 0 0 0 35px;

                    li,
                    li a {
                        font-size: 1.9rem;
                        padding: 0 0 15px 10px !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 760px) {
    .hochkant {
        display: flex;
        gap: 16px;
    }
}
