/* Ticket-System */

#ticketTabelle {
    table {
        width: 100%;
        margin-bottom: 80px;

        td,
        th {
            border-bottom: 1px solid #d2d2d2;
            padding: 1rem;
        }

        tr:not(:first-child):hover {
            background-color: #fff;
        }

        tr:not(:first-child) {
            cursor: pointer;
        }
    }

    h3 {
        text-align: center;
        margin: 1rem 0;
        font-size: 1.5rem;
    }
}

#ticketAnzeige {
    margin-bottom: 5rem;

    > a {
        background: $blau;
        border-radius: 5px;
        font-size: 0.9rem;
        font-weight: 600;
        padding: 10px 30px;
        margin: 20px 0 0 0;
        display: inline-block;
        color: #fff;
    }

    h3 {
        font-weight: 300;
        font-size: 1.4rem;
        text-align: center;
        margin: 1rem 0;
    }

    #ticketInfo {
        padding: 1rem 0;
        background: #eee;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-around;
        text-align: center;
    }

    .ticketAnhang {
        background-color: #fff;
        padding: 0.5rem 0;
    }

    #ticketUnterhaltung {
        overflow-y: auto;
        margin-bottom: 2rem !important;

        .message {
            width: 100%;
        }

        p {
            font-size: 1.25rem;
        }

        .outgoing,
        .incoming {
            width: 85%;
            margin-top: 1rem;

            p {
                position: relative;
                background-color: #fff;
                border: 2px solid $blau;
                border-radius: 5px;
                padding: 20px;
                margin: 0 0 4px 0;
            }
        }

        .outgoing {
            float: right;
        }

        .incoming {
            float: left;

            p {
                border-color: #ccc;
            }
        }

        .messageIcon,
        .messageDate {
            display: inline-block;
        }

        .messageDate {
            float: right;
        }

        .messageIcon,
        .messageDate {
            font-size: 0.8rem;
            color: #666;
            margin: 0 30px;
        }
    }

    #ticketAntwort,
    #ticketUnterhaltung {
        margin: auto;
    }

    #antwortFeld {
        min-width: 100%;
        border: 2px solid $blau;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 20px;
        font-size: 1.1rem;
        margin: 10px 0 0 0;
    }

    div#ticketAntwort {
        width: 85%;
        float: right;
    }

    #ticketAntwortFehler {
        text-align: center;
        margin-top: 5rem;
        font-weight: bold;
    }

    .anhangVorschau {
        max-height: 100px;
        max-width: 100px;
    }

    #ticketTabelle .button {
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
    }

    #ticketAntwort {
        input.button {
            border: 2px solid $blau;
            border-radius: 5px;
            font-size: 1.2rem;
            font-weight: 600;
            padding: 10px 50px;
            width: auto;
            height: auto;
            line-height: 25x;
        }

        form {
            border-bottom: 1px solid #ccc;
            padding: 0 0 20px 0;
            margin: 0 0 20px 0;
            font-size: 1.5rem;
        }
    }
}

/* Ticket-System Ende */
