@media (max-width: 768px) {
    .os-iOS.benutztAPP {
        #app-nav-button {
            display: block;
            height: 35px;
            width: 35px;
            background: none;
            position: absolute;
            left: 20px;
            top: 45px;
            z-index: 99999;
            background-repeat: no-repeat;
            background-size: 35px;
            background-image: url(#{$cdnPfad}/back-button.svg);
        }

        #hauptmenu #k_logo img {
            width: 400px;
            margin-left: 70px;
        }
    }

    .app-veraltet-popup {
        font-size: 26px;

        .flex {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
        }

        .appstore {
            width: 300px;
            height: auto;
        }
    }

    .os-Android .app-apple,
    .os-iOS .app-android {
        display: none;
    }
}
