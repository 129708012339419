body.adventswochen {
    &:after {
        content: "";
        z-index: -1;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0.2;
        background-image: url(#{$cdnPfad}/b/adventswochen_pattern_g.png);
        background-size: 20%;
        background-blend-mode: color-burn;
    }

    .wrapper {
        max-width: 992px;
        margin: 0 auto;

        .top {
            background: #68b592;
            padding: 30px 20px;

            p {
                color: #fff;
                max-width: 75%;
                margin: 20px 0 0 0;
                font-size: 1.4em;
                display: $text1;
            }

            .headline {
                background-image: url("#{$cdnPfad}/b/adventswochen_headline_#{$lang}.svg");
                background-size: contain;
                width: 690px;
                height: 52px;
                background-repeat: no-repeat;
            }
        }

        .wochen {
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
            margin: 16px 0 0 0;

            //grid-template-rows: repeat(3, 230px);

            .box {
                padding: 0;
                overflow: hidden;
                position: relative;
                display: flex;
                flex-direction: column;
                margin: 0;
                justify-content: end;

                img {
                    max-width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 9;
                    opacity: 0.6;
                    overflow: hidden;
                }

                &:not(.active):before {
                    content: $text1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 11;
                    color: #fff;
                    font-size: 1.5em;
                    display: flex;
                    text-align: center;
                    padding: 30px;
                    align-items: center;
                    aspect-ratio: 3/2;
                    box-sizing: border-box;
                }

                &:not(.active):after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 10;
                    mix-blend-mode: multiply;
                }

                &:not(.activ).past:before {
                    content: $textpast;
                    font-size: 2.2em;
                }

                .info {
                    display: none;
                }

                h2 {
                    text-align: center;
                    padding: 5px;
                    background: #fff;
                    position: relative;
                    z-index: 20;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    span {
                        display: block;
                        font-size: 1.3em;
                        font-weight: 600;
                    }
                }
            }

            .woche1:after {
                background-color: $blau;
            }

            .woche2:after {
                background-color: $tuerkis;
            }

            .woche3:after {
                background-color: $rot;
            }

            .woche4:after {
                background-color: #68b592;
            }

            .active {
                grid-row-start: 1;
                grid-row-end: 4;
                grid-column-start: 1;
                grid-column-end: 4;
                background-color: #fff !important;

                img {
                    opacity: 1;
                }

                h2 {
                    text-align: left;
                    padding: 20px 10px 10px 20px;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    span {
                        display: inline;
                        font-weight: normal;
                        font-size: 1em;
                        margin-right: 7px;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;

                    h2 {
                        padding: 0 0 10px 20px;
                        font-size: 1.6em;
                        font-weight: 600;
                    }

                    p {
                        padding: 0 0 0 20px;
                    }

                    a {
                        margin: 15px 0 15px 20px;
                        max-width: 300px;
                        padding: 15px 30px;
                        border-radius: 5px;
                        background-color: #68b592;
                        color: #fff;
                        font-size: 1.3em;
                        text-align: center;
                    }

                    img {
                        width: 178px;
                        position: absolute;
                        top: 410px;
                        right: 30px;
                        z-index: 20;
                    }
                }
            }
        }
    }
}

@media (max-width: 760px) {
    body.adventswochen .wrapper .wochen .box:not(.active):before {
        font-size: 1.2em;
    }

    body.adventswochen .wrapper .wochen .box h2 span {
        font-size: 1.2em;
    }

    body.adventswochen .wrapper .wochen .box h2 {
        font-size: 1.2em;
    }
}
