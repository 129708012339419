.order-info {
    .prio-versand {
        display: block;
        flex: 1 1 50%;

        p strong {
            font-weight: 700;
            font-size: inherit;
            color: $schriftfarbe;
        }

        p u {
            font-size: inherit;
            color: $schriftfarbe;
        }

        h2.prio-h2 {
            flex: 1 0 100%;
            font-size: 30px;
            margin: 0px 0 20px 0;

            span {
                color: $schriftfarbe;
                font-weight: 900;
                font-style: italic;
                font-size: inherit;
            }
        }

        .kassenausgang-prio-versand-text {
            display: none !important;
            align-items: center;
        }

        .prio-versand-info-text {
            display: flex;
        }

        .kassenausgang-prio-versand-text p {
            color: $schriftfarbe;
            margin: 0 30px 0 10px;
            font-weight: 400;
        }

        .prio-versand-info-text p {
            color: $schriftfarbe;
            font-size: 16px;
            margin: 20px 0 0 2vw;
            flex: 1 0 25%;
            font-weight: 400;
        }

        .kassenausgang-prio-versand {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.5rem;
            padding: 1rem 0;
            margin-bottom: 10px;
            color: $schriftfarbe;
            background: url(#{$cdnPfad}/ul/prio-versand-icon.svg) right 0 no-repeat;
        }

        .prio-versand-no {
            margin: 20px 0 0 0;
            cursor: pointer;
            flex: 1 0;
            text-align: right;
            align-self: flex-end;
        }

        .kassenausgang-prio-versand input {
            display: block;
            visibility: visible !important;
            width: 100% !important;
            height: 40px !important;
            position: absolute !important;
            top: 0;
            left: 0;
            cursor: pointer;
            transition: unset !important;
            transform: unset !important;

            &:focus {
                border: 0px solid transparent !important;
            }
        }

        .kassenausgang-prio-versand-checkbox {
            height: 50px !important;
            background: $blau;
            border-radius: 5px;
            color: #fff;
            margin: 20px 0 0px 0 !important;
            text-align: center;
            padding: 15px 2vw 15px 20px;
            box-sizing: border-box;
            position: relative;
            display: flex;

            span,
            div {
                color: #fff;
            }

            &:before {
                content: "";
                width: 16px;
                height: 16px;
                background: #fff;
                border-radius: 16px;
                border: 1px solid $blau;
                cursor: pointer;
                margin: auto 20px auto 0;
                display: block;
            }

            &.checked:before {
                background: $tuerkis;
                box-shadow: inset 0 0 0 2px white;
            }
        }

        .kassenausgang-prio-versand-aufschlag {
            white-space: nowrap;
            margin: 0 0 0 20px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .kassenausgang-prio-versand-aufschlag {
        text-align: right;
        flex: 1;
    }
}

@media only screen and (max-width: 760px) {
    .order-info .prio-versand .prio-versand-info-text {
        flex-direction: column;
    }

    .kassenausgang-prio-versand-text p {
        font-size: 34px !important;
    }

    .order-info .prio-versand-info-text p {
        font-size: 28px !important;
        line-height: 130%;
    }

    h2.prio-h2 {
        font-size: 36px !important;
    }

    .order-info .prio-versand .kassenausgang-prio-versand-checkbox {
        padding: 0 30px;
        height: 70px !important;
        align-items: center;

        span,
        div {
            font-size: 28px !important;
        }
    }
}
