figure.video:hover {
    .vid-controls {
        transform: translateY(0);
        transition: all 0.2s;
    }
}

.video3d {
    background: #fff;
}

.vid-controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    z-index: 1000;
    transition: all 0.2s;

    .buttons {
        padding: 0 10px;
        height: 100%;

        button {
            background: none;
            border: 0;
            outline: 0;
            cursor: pointer;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: flex-end;

            &:before {
                content: "\25B6";
                width: 30px;
                height: 30px;
                display: inline-block;
                font-size: 50px;
                color: $blau;
                line-height: normal;
            }

            &.play:before {
                content: "\25B6";
                font-size: 25px;
                margin: 5px 0 10px 3px;
            }

            &.pause:before {
                content: "=";
                transform: rotate(90deg);
                transform-origin: right;
                margin: 0 0 -3px 0;
            }
        }
    }
}
