// Farben
$blau: #2f80cb;
$background: #f2f2f2;
$hellgrau: #e8e8e8;
$dgrau: #d9d9d9;
$agrau: #64645f;
$bgrau: #737982;
$gruen: #1cbc54;
$rot: #e04833;
$orange: #f4ad00;
$tuerkis: #2aafba;
$schriftfarbe: #737982;

// Schatten
$schattenU: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
$schattenU2: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);

// Fonts
$mobile-font-size: 26px;

// Other

$local_gulp: 0 !default; //darf nicht auf 1 gesetzt werden!!!!!!!!!!!!!!!!
