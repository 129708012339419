.adventskalender {
    display: flex;
    flex-direction: column;
    align-items: center;

    .unser-adventskalender {
        max-width: 100%;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
    }

    h1,
    .intro {
        text-align: center;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(5, 150px);
        grid-template-rows: repeat(9, 150px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        justify-content: center;
        margin: 30px 0;
        background: url(#{$cdnPfad}/xmas-background.svg) repeat #8a9480;
        background-size: 400px;
        max-width: 750px;
        overflow-x: hidden;

        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            cursor: default;

            &.heute {
                cursor: pointer;
            }

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 50px;
                font-weight: 700;
                color: #ffffff;
                width: 90px;
                height: 90px;
                background: #e25b51;
                border-radius: 5px;
                box-shadow: $schattenU2;
            }
        }
    }

    .tag1 {
        grid-area: 1 / 3 / 2 / 4; // <row-start> / <column-start> / <row-end> / <column-end>;
    }

    .tag2 {
        grid-area: 9 / 3 / 10 / 6;
    }

    .tag3 {
        grid-area: 6 / 1 / 7 / 2;
    }

    .tag4 {
        grid-area: 2 / 4 / 3 / 5;
    }

    .tag5 {
        grid-area: 9 / 1 / 10 / 2;
    }

    .tag6 {
        grid-area: 5 / 2 / 7 / 4;
    }

    .tag7 {
        grid-area: 4 / 3 / 5 / 4;
    }

    .tag8 {
        grid-area: 7 / 4 / 8 / 5;
    }

    .tag9 {
        grid-area: 2 / 1 / 4 / 2;
    }

    .tag10 {
        grid-area: 4 / 1 / 5 / 3;
    }

    .tag11 {
        grid-area: 4 / 4 / 5 / 6;
    }

    .tag12 {
        grid-area: 1 / 4 / 2 / 6;
    }

    .tag13 {
        grid-area: 7 / 1 / 9 / 2;
    }

    .tag14 {
        grid-area: 3 / 2 / 4 / 3;
    }

    .tag15 {
        grid-area: 2 / 2 / 3 / 4;
    }

    .tag16 {
        grid-area: 5 / 1 / 6 / 2;
    }

    .tag17 {
        grid-area: 7 / 5 / 9 / 6;
    }

    .tag18 {
        grid-area: 2 / 5 / 4 / 6;
    }

    .tag19 {
        grid-area: 8 / 2 / 10 / 3;
    }

    .tag20 {
        grid-area: 1 / 1 / 2 / 3;
    }

    .tag21 {
        grid-area: 7 / 2 / 8 / 4;
    }

    .tag22 {
        grid-area: 3 / 3 / 4 / 5;
    }

    .tag23 {
        grid-area: 8 / 3 / 9 / 5;
    }

    .tag24 {
        grid-area: 5 / 4 / 7 / 6;
    }
}

.adventskalender,
.adventskalender-teaser {
    max-width: 740px;
    margin: 0 auto;

    .teaserbild {
        border: 4px solid #f2f2f2;
        border-radius: 20px;
        height: auto;
        max-width: 100%;
    }

    .intro {
        margin: 0 auto;
        text-align: left;

        h1 {
            text-align: left;
            font-size: 18px;
            font-weight: 700;
            margin: 15px 0 10px 0;
        }
    }
}

@media (max-width: 760px) {
    .adventskalender {
        .grid {
            grid-template-rows: repeat(30, 150px);

            > a {
                > div {
                    width: 200px;
                    height: 200px;
                    font-size: 90px;
                }
            }
        }

        .tag1 {
            grid-area: 3 / 3 / 5 / 6; // <row-start> / <column-start> / <row-end> / <column-end>;
        }

        .tag2 {
            grid-area: 28 / 4 / 30 / 6;
        }

        .tag3 {
            grid-area: 16 / 1 / 19 / 3;
        }

        .tag4 {
            grid-area: 5 / 4 / 7 / 6;
        }

        .tag5 {
            grid-area: 28 / 3 / 26 / 6;
        }

        .tag6 {
            grid-area: 19 / 3 / 17 / 6;
        }

        .tag7 {
            grid-area: 14 / 1 / 11 / 4;
        }

        .tag8 {
            grid-area: 25 / 1 / 23 / 3;
        }

        .tag9 {
            grid-area: 3 / 1 / 5 / 3;
        }

        .tag10 {
            grid-area: 10 / 6 / 14 / 4;
        }

        .tag11 {
            grid-area: 16 / 1 / 14 / 3;
        }

        .tag12 {
            grid-area: 1 / 4 / 3 / 6;
        }

        .tag13 {
            grid-area: 23 / 1 / 21 / 4;
        }

        .tag14 {
            grid-area: 10 / 6 / 7 / 4;
        }

        .tag15 {
            grid-area: 5 / 1 / 8 / 4;
        }

        .tag16 {
            grid-area: 17 / 6 / 14 / 3;
        }

        .tag17 {
            grid-area: 26 / 3 / 23 / 6;
        }

        .tag18 {
            grid-area: 7 / 1 / 10 / 4;
        }

        .tag19 {
            grid-area: 30 / 1 / 28 / 4;
        }

        .tag20 {
            grid-area: 1 / 1 / 3 / 4;
        }

        .tag21 {
            grid-area: 23 / 4 / 21 / 6;
        }

        .tag22 {
            grid-area: 13 / 2 / 8 / 4;
        }

        .tag23 {
            grid-area: 28 / 1 / 25 / 3;
        }

        .tag24 {
            grid-area: 21 / 1 / 19 / 6;
        }
    }

    .adventskalender,
    .adventskalender-teaser {
        .intro {
            h1,
            p {
                font-size: $mobile-font-size;
            }
        }
    }
}
