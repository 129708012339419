#shopFooter {
    display: flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    position: unset;

    .footer-newsletter {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 10px;

        .title {
            color: #ffffff;
            font-size: 20px;
        }

        .form {
            display: flex;
            align-items: center;
            margin: 20px 0;

            #email {
                margin-right: 40px;
                padding: 10px;
                border-radius: 5px;
                color: #4c5262;
                width: 240px;
                font-size: 14px;

                &::placeholder {
                    color: lightgray;
                }
            }

            #gender_form {
                display: flex;
                flex-direction: column;
                margin-right: 50px;
                align-items: baseline;

                .weiblich {
                    margin-bottom: 8px;
                }

                input {
                    display: none;
                    cursor: pointer;

                    &:checked {
                        + label:before {
                            background: $blau;
                        }
                    }
                }

                label {
                    position: relative;
                    cursor: pointer;

                    &:before {
                        content: " ";
                        transition: background 0.5s;
                        position: absolute;
                        transform: translate(0%, -50%);
                        top: 50%;
                        left: -18px;
                        display: block;
                        border-radius: 10px;
                        box-sizing: border-box;
                        border: 2px solid white;
                        background: white;
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            .form_button_submit {
                padding: 10px 40px;
                background: $tuerkis;
                border-radius: 5px;
                color: #ffffff;
                font-weight: 700;
                cursor: pointer;
                height: auto;
                line-height: initial;
            }

            label {
                color: #ffffff;
            }
        }

        .datenschutz {
            color: #ffffff;
        }

        .newsletter-warn {
            &.hidden {
                display: none;
            }

            border: 3px solid red;
        }
    }

    #f_text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #4c5262;
        padding: 20px;

        .content {
            display: flex;
            align-items: center;
            gap: 48px;
            margin-right: 50px;
            text-align: left;
            color: #ffffff;
        }

        .social {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 300px;
        }
    }

    .footer-fuer-checkout .content {
        display: block !important;
    }

    #shopMitte2 {
        min-height: auto;
    }

    .zahlungsarten {
        width: 100%;
    }

    > .unten {
        display: flex;
        flex-direction: column;
        background: #297ece;
        flex: 1 0 100%;
        position: sticky;

        #f_text {
            position: relative;

            span {
                color: white;
                font-size: 12px;
                display: block;
                padding: 0 16px;

                * {
                    color: white;
                    font-size: 12px;
                }
            }
        }

        > figure {
            width: 192px;
            height: 90px;
            background: white;
            float: left;
            background: white;
        }

        > ul {
            background: #1dafbb;
        }

        > .f_socs {
            display: flex;
            height: 106px;

            > li {
                flex: 1 0 auto;

                &.nl {
                    position: relative;

                    span {
                        position: absolute;
                        bottom: 20px;
                        color: #ffffff;
                        text-align: center;
                        width: 100%;
                    }
                }

                &.insta {
                    background: radial-gradient(ellipse at 100% 100%, #ee583f 8%, #d92d77 42%, #bd3381 58%);
                }
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                color: white;
                text-align: center;

                &[href*="ewsletter"] {
                    background: #1dafbb url(#{$cdnPfad}/social-newsletter.svg) 50% 42% no-repeat;
                    background-size: auto 30px;
                }

                &[href*="facebook"] {
                    background: #3a589b url(#{$cdnPfad}/social-facebook.svg) 50% 50% no-repeat;
                    background-size: auto 38px;
                }

                &[href*="youtube"] {
                    background: #e82a1b url(#{$cdnPfad}/social-youtube.svg) 50% 50% no-repeat;
                    background-size: auto 38px;
                }

                &[href*="twitter"] {
                    background: #2daae1 url(#{$cdnPfad}/social-twitter.svg) 50% 50% no-repeat;
                    background-size: auto 38px;
                }

                &[href*="instagram"] {
                    background: url(#{$cdnPfad}/social-instagram.svg) 50% 50% no-repeat;
                    background-size: auto 38px;
                }
            }
        }
    }

    .vcard {
        text-align: center;
        color: #fff;

        span {
            display: inline-block !important;
            padding: 0px !important;
            line-height: 170%;

            > span {
                padding: 0 !important;
            }
        }
    }

    > nav {
        background: #e8e8e8;
        box-shadow: inset 0px 3px 4px -1px rgba(0, 0, 0, 0.1);
        width: 100%;

        .oben {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            width: 1024px;
            margin: 0 auto;

            ul {
                flex-direction: column;
                border-left: 1px solid #e1e1e1;
                padding: 30px 20px;
                box-sizing: border-box;

                li {
                    cursor: pointer;
                    padding: 2px 0px;
                }

                a {
                    font-size: 14px;
                }
            }
        }
    }

    .zahlung-versand {
        display: flex;
        justify-content: center;

        .zahlung,
        .versand {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: baseline;
            margin: 20px 0;

            img {
                margin: 10px;
                border: 2px solid #d9d9d9;
                padding: 3px 10px;
                border-radius: 5px;
                width: 90px;
                filter: grayscale(100%) opacity(0.5);
            }
        }

        .versand {
            min-width: 270px;
            justify-content: flex-end;
        }
    }

    // .wiederArtikelZeigenBanner {
    //     background: $blau;
    // }
}

// #shopLayer[data-pp=""] {
//     .wiederArtikelZeigenBanner {
//         display: none !important;
//     }
// }

#FooterJumperBanner {
    max-width: 1760px;
    margin: 20px auto;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &.startseite {
        margin-top: 30px;

        .fb {
            margin: 0;
        }
    }

    @include clearfix;

    p a {
        color: $blau;
    }

    .fb {
        background: #fff;
        border-radius: 5px;
        padding: 18px 22px 25px 18px;
        box-sizing: border-box;
        margin: 8px;
        box-shadow: $schattenU;
        flex-shrink: 0;
        flex-grow: 1;

        li {
            list-style-type: disc;
            font-weight: 300;
            padding-bottom: 8px;
            padding-left: 10px;
        }

        &.inh {
            flex-grow: 0;
            width: 234px;
            height: auto;
        }

        .inh li {
            list-style-type: decimal;
        }

        ol,
        ul {
            margin-left: 15px;
        }

        h3 {
            color: $blau;
            font-size: 19px;
            line-height: 135%;
            margin-bottom: 25px;
            column-span: all;
        }

        h4 {
            color: $blau;
            font-size: 14px;
            line-height: 135%;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 15px;
            line-height: 130%;
            font-weight: 400;

            a,
            b,
            strong {
                font-weight: 700;
                font-size: inherit;
            }
        }

        h1,
        h2,
        h3,
        h4 {
            font-weight: 700;
        }

        &.flex {
            width: 100%;
        }
    }

    .img {
        margin: 8px;
        flex-shrink: 0;
        flex-grow: 0;

        img {
            border-radius: 5px;
            margin-bottom: 8px;
            width: 100%;
            overflow: hidden;
            height: 100%;
            object-fit: cover;
        }

        &.two img {
            height: 49%;
        }
    }

    .text {
        width: 740px;
        height: auto;
        columns: 3 300px;
    }

    .gr_1 {
        width: 234px;
        height: auto;
    }

    .gr_2 {
        width: 488px;
        height: auto;
    }

    .gr_3 {
        width: 740px;
        height: auto;
        columns: 3 300px;

        &.img {
            columns: 1;
        }
    }
}

// @media (max-width: 840px) {
//     #shopFooter .wiederArtikelZeigenBanner {
//         font-size: 2.2rem;
//         width: fit-content;
//         padding: 20px 40px;
//         margin: 0px auto 60px auto;
//     }
// }

.laenderauswahl {
    display: flex;
    align-items: center;
    background: transparent;
    max-width: fit-content;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;

    &:hover {
        background: $bgrau;
    }

    > img {
        margin-right: 20px;
        height: 30px;
    }
}

@media (max-width: 760px) {

    #shopFooter {
        > .unten {
            > .f_socs {
                height: 188px;
            }
        }

        .laenderauswahl {
            margin: 20px auto;
            padding: 25px 50px;
            background: $bgrau;
            font-size: 28px;
        }

        .footer-newsletter {
            .title {
                font-size: 34px;
            }

            form {
                width: 100%;
            }

            .form {
                padding: 0 20px;
                flex-direction: column;

                #email {
                    font-size: 28px;
                    width: 80%;
                    text-align: center;
                    margin: 15px 30px;
                    padding: 20px;
                }

                #gender_form {
                    flex-direction: row;
                    margin: 20px 0;

                    .weiblich,
                    .maennlich {
                        display: flex;
                        align-items: center;
                        margin: 0 10px;
                    }

                    label {
                        font-size: 28px;
                        margin: 0 20px;

                        &:before {
                            left: -30px;
                            width: 22px;
                            height: 22px;
                        }
                    }

                    input {
                        padding: 15px;
                        width: 20px;
                        height: 20px;
                    }
                }

                .form_button_submit {
                    font-size: 28px;
                    padding: 20px 40px;
                }
            }

            .datenschutz {
                font-size: 28px;
                text-align: center;
            }
        }

        #f_text {
            flex-direction: column;

            .content {
                margin: 0 0 30px 0;
                text-align: center;
                flex-direction: column;
            }

            .social {
                justify-content: center;

                img {
                    height: 90px;
                }
            }
        }
    }
}
