iframe {
    &.mfp-iframe {
        padding: 0;
    }
}

.seite-im-iframe {
    padding: 20px;
    background: #f2f2f2;

    #onesignal-slidedown-container {
        display: none;
    }

    #shopHeader,
    #shopFooter,
    div[id*="tsbadge"],
    [id^="trustbadge-container"] {
        display: none !important;
    }

    #shopLayer {
        padding-top: 0;
    }

    #shopMain {
        padding: 0;
    }

    #service_wrapper #service_rechts {
        flex: unset;
    }

    .iframe-display-none {
        display: none !important;
    }
}

@media (max-width: 1404px) {
    .seite-im-iframe {
        #service_mitte {
            width: 100% !important;
        }
    }
}

/* popup v2 */

.white-popup {
    position: relative;
    background: #fff;
    padding: 20px;
    width: auto;
    max-width: 700px;
    margin: 20px auto;
    border-radius: 5px;

    h1 {
        font-size: 1.8rem;
        margin: 0 0 1rem 0;
    }

    h2 {
        font-size: 1.6rem;
        margin: 0 0 1rem 0;
    }

    h3 {
        font-size: 1.4rem;
        margin: 0 0 1rem 0;
    }

    p {
        margin: 0 0 1rem 0;
    }

    a {
        color: $blau;
    }

    .umtauschanleitung {
        display: none;
    }
}
