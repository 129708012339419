// Modelbilder im SE
.se .artikel_1.modelBildAnzeigen figure {
    width: 100%;
    height: 236px;
    position: absolute;
    top: 0;
}

.artikel_1.modelBildAnzeigen .se_farben {
    margin: 207px auto 2px auto;
}

.se figure img {
    max-width: 204px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition:
        left 1.3s,
        opacity 0.7s;
}

ul.se:not(.filter_farbe) .artikel_1.modelBildAnzeigen:not(.mb-reverse) figure:after,
ul.se .artikel_1.modelBildAnzeigen.mb-reverse:hover figure:after {
    content: "";
    display: block;
    background-image: var(--bg-model);
    background-size: contain;
    opacity: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transition: opacity 200ms ease-in-out;
    transition-delay: 200ms;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

body.zk1_17 .artikel_1.modelBildAnzeigen .male figure:after,
body.zk1_18 .artikel_1.modelBildAnzeigen .female figure:after,
body.filter-farbe .artikel_1.modelBildAnzeigen figure:after {
    content: unset;
}

ul.se li.modelBildAnzeigen:not(.mb-reverse):hover figure:after {
    content: unset !important;
}

ul.se {
    .se_beobachten,
    .rating {
        filter: brightness(0.9);
    }
}

// ENDE Modelbilder im SE
