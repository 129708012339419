@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
        -webkit-transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
        -webkit-transform: none;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
        -webkit-transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
        -webkit-transform: none;
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes jello {
    0%,
    11.1%,
    to {
        transform: none;
        -webkit-transform: none;
    }

    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg);
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}

@keyframes jello {
    0%,
    11.1%,
    to {
        transform: none;
        -webkit-transform: none;
    }

    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg);
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}

.jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    transform-origin: center;
    -webkit-transform-origin: center;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes flipInX {
    0% {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    0%,
    40% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    40% {
        transform: perspective(400px) rotateX(-20deg);
        -webkit-transform: perspective(400px) rotateX(-20deg);
    }

    60% {
        transform: perspective(400px) rotateX(10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotateX(-5deg);
        -webkit-transform: perspective(400px) rotateX(-5deg);
    }

    to {
        transform: perspective(400px);
        -webkit-transform: perspective(400px);
    }
}

@keyframes flipInX {
    0% {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    0%,
    40% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    40% {
        transform: perspective(400px) rotateX(-20deg);
        -webkit-transform: perspective(400px) rotateX(-20deg);
    }

    60% {
        transform: perspective(400px) rotateX(10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotateX(-5deg);
        -webkit-transform: perspective(400px) rotateX(-5deg);
    }

    to {
        transform: perspective(400px);
        -webkit-transform: perspective(400px);
    }
}

.flipInX {
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
}

@keyframes ourFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ourFadeIn {
    animation-name: ourFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @include browserPrefixes(animation-name, ourFadeIn);
    @include browserPrefixes(animation-fill-mode, forwards);
}
